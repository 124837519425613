import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Multi-Theme`}</h2>
    <p>{`After implementing the gradle config, next step is to configure the theme on android. Change the parent theme and extend to Legion Theme`}</p>
    <p>{`Light Theme`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`  <style name="YourTheme" parent="Legion.Light">
      ...
  </style>
`}</code></pre>
    <br />
    <p>{`Dark Theme `}<strong parentName="p">{`[Work In Progress]`}</strong></p>
    <InlineNotification kind="info" mdxType="InlineNotification">
      <p><strong parentName="p">{`Info`}</strong>{` : Currently the dark theme is not yet available, so extending with the `}<strong parentName="p">{`Legion.Dark`}</strong>{`
theme will result in a Light Mode like extending the `}<strong parentName="p">{`Legion.Light`}</strong>{` theme`}</p>
    </InlineNotification>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`  <style name="YourTheme" parent="Legion.Dark">
      ...
  </style>
`}</code></pre>
    <br />
    <br />
    <h3><strong parentName="h3">{`Available Themes`}</strong></h3>
    <p>{`Not all squad are also provided like tribe, some are ready for use some aren’t since needs will differ across platforms too. Here are the squads that are ready to use by order:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Theme Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Tribe / Squad Code`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Tribe / Squad Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme Agree`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`agl`}</inlineCode>{`, `}<inlineCode parentName="td">{`agr`}</inlineCode>{`, `}<inlineCode parentName="td">{`aqf`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Agree Livestock, Agree Culture, Agree Fisheries`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Theme EWZ`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`mtn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`MyTEnS`}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <br />
    <h3><strong parentName="h3">{`Apply Prebuild Theme`}</strong></h3>
    <p>{`Latest steps you can configure your selected theme with extend your project theme inside your `}<inlineCode parentName="p">{`themes.xml`}</inlineCode>{` files`}</p>
    <p><strong parentName="p">{`Tribe Agree`}</strong></p>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Theme Agree Culture" mdxType="AccordionItem">
        <br />
        <pre><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<style name="YourTheme" parent="AgreeCulture">
  ...
</style>
`}</code></pre>
      </AccordionItem>
      <AccordionItem title="Theme Agree Fisheries" mdxType="AccordionItem">
        <br />
        <pre><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<style name="YourTheme" parent="AgreeFisheries">
  ...
</style>
`}</code></pre>
      </AccordionItem>
      <AccordionItem title="Theme Agree Livestok" mdxType="AccordionItem">
        <br />
        <pre><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<style name="YourTheme" parent="AgreeLivestock">
  ...
</style>
`}</code></pre>
      </AccordionItem>
    </Accordion>
    <br />
    <div className="divi" />
    <br />
    <p><strong parentName="p">{`Tribe EWZ`}</strong></p>
    <Accordion mdxType="Accordion">
      <AccordionItem title="Theme MyTens" mdxType="AccordionItem">
        <br />
        <pre><code parentName="pre" {...{
            "className": "language-xml"
          }}>{`<style name="YourTheme" parent="Mytens">
  ...
</style>
`}</code></pre>
      </AccordionItem>
    </Accordion>
    <br />
    <h3><strong parentName="h3">{`Customize Themes`}</strong></h3>
    <p>{`The majority of `}<strong parentName="p"><em parentName="strong">{`Legion Components`}</em></strong>{` use primary and secondary colors, you can override color set colorPrimary and colorSecondary to change all colors in the `}<strong parentName="p"><em parentName="strong">{`Legion Component`}</em></strong>{`. Please Replace color with your own pallete colors.`}</p>
    <p>{`If you using `}<em parentName="p">{`prebuild`}</em>{` theme, please extend your project theme into `}<em parentName="p">{`prebuild`}</em>{` theme. if you want manual crafting theme, you can extend project theme with Legion`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-xml",
        "metastring": "title=\"themes.xml\"",
        "title": "\"themes.xml\""
      }}>{`<style name="YourTheme" parent="Legion.Light">
    ...
    <item name="colorPrimary_pressed">@color/aqf_pressed</item>
    <item name="colorPrimary_hover">@color/aqf_hover</item>
    <item name="colorPrimary">@color/aqf_normal</item>
    <item name="colorPrimary40">@color/aqf_40</item>
    <item name="colorPrimary30">@color/aqf_30</item>
    <item name="colorPrimary20">@color/aqf_20</item>
    <item name="colorPrimary10">@color/aqf_10</item>
    <item name="colorSecondary_pressed">@color/poultry_70</item>
    <item name="colorSecondary_hover">@color/poultry_60</item>
    <item name="colorSecondary">@color/poultry_50</item>
    <item name="colorSecondary40">@color/poultry_40</item>
    <item name="colorSecondary30">@color/poultry_30</item>
    <item name="colorSecondary20">@color/poultry_20</item>
    <item name="colorSecondary10">@color/poultry_10</item>
    ...
</style>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      